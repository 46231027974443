import React, {useState} from "react";
import "./App.scss";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Calc from "./Component/Calc";

import ResultTabs from "./Component/ResultTabs";
// import AssociationLogo from "./Images/association.jpg";
// import FacultadLogo from "./Images/facultad.png";
import CutOff from "./Images/cutoff.png";

export class CalcResult {
  constructor(
    public gender: string,
    public age: number,
    public lnFit: number,
    public result: number
  ){}
}
export const Variables = {
  log_1FitNuev: 0.472554132971005,
  sexo_RefFemale: 0.595714964142343,
  edad: 0.0231625668638798,
  constante: -3.87315652963903,
  puntoDeCorte: 0.1375
}

function App() {
  const [result, setResult] = useState<number | null>(null);
  return (
    <div className="App">
      {/* navbar section */}
      <Navbar bg="light">
        <Container>
          <Navbar.Brand>
            IDAFIT <br /> CALC
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container className="container-main">
        <div>
          <p>
            Based Risk Calculator for Assessing Patients with Iron Deficiency
            Anemia
          </p>
          <h3>According to Fecal Immunochemical Testing (IDAFIT)</h3>
          <Calc setResult={setResult}/>
          <h4>Result</h4>
          <p>
            {result ? `${result} -->  ` : 'Please fill the form to get result'}
            {result && result > Variables.puntoDeCorte && 'High risk of having a significant colorectal lesion'}
            {result && result <= Variables.puntoDeCorte && 'Low risk of having a significant colorectal lesion'}
          </p>
          {
            result && <>
              <img src={CutOff} alt="" />
              <p>The selected cut-off for the estimated risk of having a significant colorrectal lesion is 0.1375 ​</p>
            </>
          }
          <ResultTabs result={result}/>
        </div>
        {/* <div className="images"> */}
          {/* <img src={AssociationLogo} alt="" /> */}
          {/* <img src={FacultadLogo} alt="" /> */}
        {/* </div> */}
      </Container>
    </div>
  );
}

export default App;
