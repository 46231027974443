import React, { FormEvent, useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import "./Calc.scss";

import { Variables, CalcResult } from "../App";


export default function Calc({ setResult }: { setResult: (value: number) => void }): JSX.Element {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState<string>('Please fill all required fields.');
  const [gender, setGender] = useState<number | null>(null);
  const [age, setAge] = useState<number | null>(null);
  const [fitValue, setFitValue] = useState<number | undefined>();

  const saveToDb = async (result: CalcResult) => {
    fetch(process.env.REACT_APP_API_URI as string, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify(result)
    }).then(res => res.json()).then(data => console.log(data))
  }

  const onGenderChange = ({ target: { value } }: { target: { value: string } }) => {
    setGender(parseInt(value));
    setHasError(false);
  };

  const onAgeChange = ({ target: { value } }: { target: { value: string } }) => {
    setAge(parseInt(value));
    setHasError(false);
  };

  const onFitValueChange = ({ target: { value } }: { target: { value: string } }, convert: boolean) => {
    const convertedValue = convert ? parseInt(value) / 5 : parseInt(value);
    setFitValue(convertedValue);
    setHasError(false);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (gender === null || Number.isNaN(gender)) {
      setError('Please provide a gender.')
      setHasError(true);
      return;
    }

    if (age === null || Number.isNaN(age)) {
      setError('Please provide an age.')
      setHasError(true);
      return;
    }

    if (fitValue === undefined || fitValue === 0 || fitValue === null || Number.isNaN(fitValue)) {
      setError('Please provide appropriate fit value.')
      setHasError(true);
      return;
    }

    const lnFIT = Math.log(fitValue as number)

    const result = 1 / (1 + Math.exp(-(Variables.constante + Variables.log_1FitNuev * Math.log(lnFIT + 1) + Variables.sexo_RefFemale * gender + Variables.edad * (age as number))));

    if(Number.isNaN(result)) {
      setError('Please check the input values provided again.')
      setHasError(true);
      return;
    }
    setResult(parseFloat(result.toFixed(4)));

    const genderString = gender === 1 ? 'male' : 'female';
    const newResult = new CalcResult(
      genderString,
      age,
      lnFIT,
      result
    );

    saveToDb(newResult);
  }

  return (
    <Form className="calc-form">
      <Form.Group as={Row} className="mb-3" controlId="formGender">
        <Form.Label column sm="2">
          Gender
        </Form.Label>
        <Col sm="10">
          <div key={`inline-radio`} className="pt-2">
            <Form.Check
              inline
              label="Male"
              value={1}
              name="gender"
              type="radio"
              id={`inline-radio-1`}
              onChange={onGenderChange}
            />
            <Form.Check
              inline
              label="Female"
              value={0}
              name="gender"
              type="radio"
              id={`inline-radio-2`}
              onChange={onGenderChange}
            />
          </div>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formAge">
        <Form.Label column sm="2">
          Age
        </Form.Label>

        <Col sm="8">
          <InputGroup hasValidation>
            <Form.Control type="number" placeholder="25" onChange={onAgeChange} />
            <InputGroup.Text>years</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formFitValue">
        <Form.Label column sm="2">
          FIT value
        </Form.Label>

        <Col sm="8">
          <InputGroup hasValidation>
            <Form.Control type="number" placeholder="50" value={fitValue ? fitValue * 5 : ''} onChange={(e) => onFitValueChange(e, true)} />
            <InputGroup.Text>ng/ml</InputGroup.Text>
          </InputGroup>
          <InputGroup hasValidation>
            <Form.Control type="number" placeholder="10" value={fitValue} onChange={(e) => onFitValueChange(e, false)} />
            <InputGroup.Text>ug/g</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>
      {hasError && <p className="error">{error}</p>}
      <Button variant="primary" type="submit" onClick={onSubmit}>
        Calculate
      </Button>
    </Form>
  );
}
