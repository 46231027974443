import React, { useEffect, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import RightLogo from "../Images/right.png";
import GroupLogo from "../Images/group.png";
import DocLogo from "../Images/docs.png";

import { Variables } from "../App";

function ResultTabs({ result }: { result: number | null }) {
  const [key, setKey] = useState("creator insights");

  const onTabSelect = (k: string | null): void => {
    if (k !== null) setKey(k);
  };

  useEffect(() =>{
    if(result)
      setKey("next steps")
  }, [result])

  return (
    <Tabs
      style={{
        marginTop: 50,
        fontSize: 14
      }}
      id="controlled-tab-example"
      activeKey={key}
      onSelect={onTabSelect}
      className="mb-3"
    >
      <Tab
        eventKey="next steps"
        title={
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img width="20px" src={RightLogo} alt="" /> Next Steps
          </span>
        }
        disabled={typeof(result) !== 'number' ? true : false}
      >
        <p>
          {result && result <= Variables.puntoDeCorte && `A FIT-based combined model result ${result} indicates a likely of ${(result * 100).toFixed(1)}% of having a colorectal cancer or
          any other significant colorectal lesion. On this condition the work-up
          should be initiated with a gastroscopy.`}
          {result && result > Variables.puntoDeCorte && `A FIT-based combined model result ${result}
          indicates a likely of ${(result * 100).toFixed(1)}% of having a colorectal cancer or any
          other significant colorectal lesion. On this condition the work-up
          must be initiated with colonoscopy and gastroscopy at the same
          time.`}
        </p>
      </Tab>
      <Tab
        eventKey="evidence"
        title={
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img width="22px" src={DocLogo} alt="" /> Evidence
          </span>
        }
        disabled
      >
        <p>Coming soon</p>
      </Tab>
      <Tab
        eventKey="creator insights"
        title={
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{
                paddingRight: 4,
              }}
              width="20px"
              src={GroupLogo}
              alt=""
            />{" "}
            Creators
          </span>
        }
      >
        <p>
          <strong>Goretti Hernández</strong>
          <sup>1*</sup>,<strong>Dalia Elena Morales-Arraez</strong>
          <sup>1*</sup>
          ,Guillermo Garcia Rayado<sup>2</sup>, Gonzalo Hijos Mallada
          <sup>2</sup>, Nereida Fernández Fernández<sup>3</sup>,Luisa de Castro
          Parga<sup>4</sup>,María-Victoria Álvarez-Sánchez<sup>5</sup>, Carolina
          Olano<sup>6</sup>, Daniel Rodríguez-Alcaide<sup>7</sup>
          ,Carla Amaral González<sup>1</sup>, Inmaculada Alonso Abreu
          <sup>1</sup>,David Nicolás<sup>1</sup>,Marta Carrillo Palau
          <sup>1</sup>,Antonio Z. Gimeno-García<sup>1</sup>,Enrique
          González-Dávila<sup>8</sup>,Enrique Quintero<sup>1,9</sup>
        </p>
        <p>
          1. Servicio de Aparato Digestivo; Hospital Universitario de Canarias;
          2. Hospital Clínico Universitario Lozano Blesa, Zaragoza; 3. Hospital
          AlvaroCunqueiro de Vigo; 4. Complejo Hospitalario Universitario de
          Vigo; 5. Complejo Hospitalario Universitario de Pontevedra; 6. Clínica
          de Gastroenterología.Facultad de Medicina. Montevideo; Uruguay. 7.
          Hospital Universitario de Móstoles. 8. Departamento de Matemáticas,
          Estadística e Investigación Operativa. Instituto IMAULL. Universidad
          de La Laguna. 9. Departamento de Medicina Interna. Instituto
          Universitario de Tecnologías Biomédicas (ITB) & Centro de
          Investigación Biomédica de Canarias (CIBICAN). Universidad de La
          Laguna. Tenerife. Spain
        </p>
        <p>* These authors contributed equally to this work.</p>
      </Tab>
    </Tabs>
  );
}

export default ResultTabs;
